import { routeFactory } from "@/RouteFactory";
import { apolloClient } from "@/apollo-client/ApolloClient";
import { ErrorWithPath } from "@/common/error/BannerErrorWithLinks";
import { usePrompt } from "@/common/hooks/usePrompt";
import { WizardComponent } from "@/features/create-report-wizard/WizardComponent";
import { appendNotification } from "@/features/notification-center/redux/slice";
import { createNotificationCreateAgreementDocuments } from "@/features/notification-center/utils";
import {
  ChildCustomersByStatusFragment,
  CreateAgreementReportMutation,
  CreateAgreementReportMutationVariables,
  HeadAgreementStatusCode,
  ReportScreenCustomerFragment,
  ReportScreenHeadagreementFragment,
  ReportScreenQuery,
  RoutesValidationQuery,
  RoutesValidationQueryVariables,
  SubscriberContactsForReportFragment,
  UpdateHeadAgreementTextsMutation,
  UpdateHeadAgreementTextsMutationVariables,
} from "@/gql/graphql";
import { useLazyQuery } from "@apollo/client";
import {
  createStandardTableInitialState,
  useLocalStateTableContext,
} from "@stenajs-webui/grid";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { AgreementSelectionStep } from "../agreement-selection/AgreementSelectionStep";
import { MultiLegAgreementsTableConfigColumnKeys } from "../agreement-selection/common/multi-leg-agreements-table-config";
import { RouteAgreementsTableConfigColumnKeys } from "../agreement-selection/common/route-agreements-table-config";
import {
  FormattingSize,
  ReportOption,
} from "../agreement-selection/common/types";
import { ConditionsWithLastPage } from "../conditions-with-last-page/ConditionsWithLastPage";
import { CustomerDetailsWithSpecificationsStep } from "../customer-details-with-specifications/CustomerDetailsWithSpecificationsStep";
import SurchargeStep from "../surcharges/SurchargesStep";
import { AdditionalSurchargeTableColumnKeys } from "../surcharges/common/surcharges-table-config";
import { useAdditionalSurchargeDocuments } from "../surcharges/hooks/UseAdditionalSurchargeDocuments";
import { getStepConfigurations } from "./getStepConfigurations";
import { createAgreementReportMutation } from "./queries/createDocumentMutation";
import { updateHeadAgreementTextsMutation } from "./queries/updateHeadAgreementTextsMutation";
import { validateRoutesForAgreementQuery } from "./queries/validateRouteAgreementsQuery";

const initialReportOptionsState: ReportOption = {
  formattingSize: FormattingSize.Large,
  showSeaAreaHeader: false,
  includeArticle: false,
  includeMatrix: false,
  showAcc: false,
  showLoadedStatus: false,
  disabled: false,
};
interface WizardWrapperProps {
  headAgreement: ReportScreenHeadagreementFragment;
  customer: ReportScreenCustomerFragment;
  childCustomers: ChildCustomersByStatusFragment[];
  agreementTexts: ReportScreenQuery["productPrice"]["agreementTexts"]["byAgreementTextCodes"];
  reportType: string;
  childrenForReport: string[];
  parent?: number;
}

export const WizardWrapper = ({
  headAgreement,
  customer,
  childCustomers,
  agreementTexts,
  reportType,
  childrenForReport,
  parent,
}: WizardWrapperProps) => {
  const navigate = useNavigate();
  const [
    selectedCustomerSubscriberContact,
    setSelectedCustomerSubscriberContact,
  ] = useState<SubscriberContactsForReportFragment | undefined>(
    customer?.subscriberContactsForReport[0]
  );
  const [errorMessages, setErrorMessages] = useState<ErrorWithPath[]>([]);
  const [includeCreditLimit, setIncludeCreditLimit] = useState(false);
  const [selectedAgreements, setSelectedAgreements] = useState<{
    selectedRouteAgreements: ReportScreenHeadagreementFragment["routeAgreements"];
    selectedMultilegRouteAgreements: ReportScreenHeadagreementFragment["multiLegAgrRoutes"];
  }>();
  const [lastPageText, setLastPageText] = useState(
    headAgreement?.lastPage || ""
  );
  const [isNextAvailable, setIsNextAvailable] = useState(false);
  const [isCompletingWizard, setIsCompletingWizard] = useState(false);

  const [conditionsTexts, setConditionsTexts] = useState<{
    condition: string;
    agreementTexts: ReportScreenQuery["productPrice"]["agreementTexts"]["byAgreementTextCodes"];
  }>({ condition: headAgreement?.condition ?? "", agreementTexts });

  const [specificationsText, setSpecificationsState] = useState(
    headAgreement?.specifications || ""
  );

  const [reportOptions, setReportOptions] = useState<ReportOption>(
    initialReportOptionsState
  );
  const [includeThresholds, setIncludeThresholds] = useState(false);
  const yearValidFrom = Number(
    headAgreement?.valid.start?.isoString.split("-")[0]
  );
  const yearValidTo = Number(headAgreement?.valid.end?.isoString.split("-")[0]);

  const [reportCreated, setReportCreated] = useState(false);

  usePrompt({
    message: "You have unsaved changes, would you like to leave the page?",
    shouldBlock: !reportCreated,
  });

  const sectionalRouteAgreements = headAgreement.routeAgreements.filter(
    (ra) =>
      !ra.multiLegAgrRouteId && ra.statusCode !== HeadAgreementStatusCode.Void
  );

  const multiLegAgreements = headAgreement.multiLegAgrRoutes.filter(
    (multiLegAgreement) =>
      multiLegAgreement.statusCode !== HeadAgreementStatusCode.Void
  );

  const modeOfPaymemtOptions = (modeOfPaymemt: string): string => {
    switch (modeOfPaymemt) {
      case "I":
        return "Invoice";
      case "C":
        return "Cash";
      default:
        return "not available";
    }
  };

  const currentMonth = new Date().getUTCMonth();

  const getReportsForCustomers = () => {
    if (childrenForReport.length === 0) {
      return [
        {
          customerNumber: customer.custNo,
          customerCountry: customer.countryCode || "SE",
        },
      ];
    } else {
      return childrenForReport.map((id) => {
        const child = [...childCustomers, customer].find(
          (childcustomer) => childcustomer.id === id
        );
        if (!child?.custNo) {
          throw new Error("missing customer number");
        }
        return {
          customerNumber: child.custNo,
          customerCountry: child.countryCode || "SE",
        };
      });
    }
  };

  const { tableContext: routesAgreementTableContext } =
    useLocalStateTableContext(
      "route-agreements-table",
      createStandardTableInitialState<RouteAgreementsTableConfigColumnKeys>(
        "BusinessRegion"
      )
    );

  const { tableContext: multilegRoutesAgreementTableContext } =
    useLocalStateTableContext(
      "multileg-route-agreements-table",
      createStandardTableInitialState<MultiLegAgreementsTableConfigColumnKeys>()
    );

  const { tableContext: additionalSurchargeDocumentsTableContext } =
    useLocalStateTableContext(
      "surcharge-table",
      createStandardTableInitialState<AdditionalSurchargeTableColumnKeys>()
    );

  const selectedMultiLegIds =
    multilegRoutesAgreementTableContext.state.selectedIds.selectedIds;
  const selectedRouteAgreementIds =
    routesAgreementTableContext.state.selectedIds.selectedIds;
  const selectedSurchargeDocuments =
    additionalSurchargeDocumentsTableContext.state.selectedIds.selectedIds;

  const createDocument = async () =>
    apolloClient.mutate<
      CreateAgreementReportMutation,
      CreateAgreementReportMutationVariables
    >({
      mutation: createAgreementReportMutation,
      fetchPolicy: "network-only",
      variables: {
        input: {
          additionalSurchargeIds: selectedSurchargeDocuments,
          agreementId: headAgreement?.id ?? "",
          agrRouteIds: selectedRouteAgreementIds,
          reportsForCustomers: getReportsForCustomers(),
          customerSubscriberContactId:
            selectedCustomerSubscriberContact?.subscriberContactId,
          includeArticle: reportOptions.includeArticle,
          includeCredLim: includeCreditLimit,
          includeMatrix: reportOptions.includeMatrix,
          includeThreshold: includeThresholds,
          multiLegAgrRouteIds: selectedMultiLegIds,
          reportType:
            reportOptions.formattingSize === FormattingSize.Large ? "L" : "S",
          showAccUnacc: reportOptions.showAcc,
          showEmptyLoaded: reportOptions.showLoadedStatus,
          statusTypeOfAgreement: reportType === "offer" ? "Offer" : "Confirm",
          includeInternalNotes: false,
          showSeaAreaHeader: true,
          showAllFilterRouteAgrs: false,
          status: 4,
          pageBreakBeforeSeaAreaHeader: false,
        },
      },
    });

  const updateHeadAgreementTexts = async () => {
    const { data } = await apolloClient.mutate<
      UpdateHeadAgreementTextsMutation,
      UpdateHeadAgreementTextsMutationVariables
    >({
      mutation: updateHeadAgreementTextsMutation,
      fetchPolicy: "network-only",
      variables: {
        input: {
          headAgreementId: headAgreement.id,
          condition: { value: conditionsTexts.condition },
          lastPage: { value: lastPageText },
          specifications: { value: specificationsText },
          rowVersion: headAgreement.rowVersion,
        },
      },
    });
    return { headAgreementData: data };
  };
  const onWizardCompletion = async () => {
    setIsCompletingWizard(true);
    setReportCreated(true);

    const { headAgreementData } = await updateHeadAgreementTexts();
    if (!headAgreementData) {
      setErrorMessages([
        { detailText: "Could not update headAgreement texts" },
      ]);
      return;
    }

    const { data } = await createDocument();
    if (data?.createAgreementReport?.reportCreated) {
      setIsCompletingWizard(false);
      navigate(
        routeFactory.productAndPrice.customer.customerHeadAgreement({
          customerId: customer.id,
          headAgreementId:
            parent && !customer.preselectedHeadAgreement?.id
              ? undefined
              : parent && customer.preselectedHeadAgreement?.id
              ? customer.preselectedHeadAgreement?.id
              : headAgreement?.id ?? "",
        })
      );
      setStartedJobNotification();
    } else {
      // TODO: handle errors better
      setReportCreated(false);
      setErrorMessages([{ detailText: "could not create Report" }]);
      setIsCompletingWizard(false);
    }
  };

  const dispatch = useDispatch();

  const setStartedJobNotification = () => {
    const numberOfReports = getReportsForCustomers().length;
    dispatch(
      appendNotification(
        createNotificationCreateAgreementDocuments(numberOfReports)
      )
    );
  };

  const [validateRoutes] = useLazyQuery<
    RoutesValidationQuery,
    RoutesValidationQueryVariables
  >(validateRoutesForAgreementQuery);
  const stepConfigurations = useMemo(() => {
    return getStepConfigurations({
      customerNumber: parent ? parent : customer.custNo,
      headAgreementId: headAgreement.id,
      validateRoutes,
      selectedMultiLegRoutesId:
        multilegRoutesAgreementTableContext.state.selectedIds.selectedIds,
      selectedRoutesId:
        routesAgreementTableContext.state.selectedIds.selectedIds,
      childrenForReport,
      setErrorMessages,
      setIsNextAvailable,
    });
  }, [
    childrenForReport,
    customer.custNo,
    headAgreement.id,
    multilegRoutesAgreementTableContext.state.selectedIds.selectedIds,
    parent,
    routesAgreementTableContext.state.selectedIds.selectedIds,
    validateRoutes,
  ]);

  const {
    additionalSurchargeDocuments,
    loading: additionalSurchargeDocumentsLoading,
  } = useAdditionalSurchargeDocuments(yearValidFrom, yearValidTo);
  return (
    <WizardComponent
      completeWizard={() => onWizardCompletion()}
      sectionalRouteAgreements={sectionalRouteAgreements}
      errorMessages={errorMessages}
      setErrorMessages={setErrorMessages}
      stepConfigurations={stepConfigurations}
      isNextAvailable={isNextAvailable}
      setIsNextAvailable={setIsNextAvailable}
    >
      <AgreementSelectionStep
        headerAgreementRoutes={sectionalRouteAgreements}
        multilegAgreementRoutes={multiLegAgreements}
        reportOptions={reportOptions}
        setReportOptions={setReportOptions}
        routesAgreementTableContext={routesAgreementTableContext}
        multilegRoutesAgreementTableContext={
          multilegRoutesAgreementTableContext
        }
        setIsNextAvailable={setIsNextAvailable}
        setSelectedAgreements={setSelectedAgreements}
      />
      <CustomerDetailsWithSpecificationsStep
        customer={customer}
        reportType={reportType}
        customerSubscribers={customer.subscriberContactsForReport || []}
        selectedCustomerSubscriberContact={selectedCustomerSubscriberContact}
        setSelectedCustomerSubscriberContact={
          setSelectedCustomerSubscriberContact
        }
        setIsNextAvailable={setIsNextAvailable}
        customerId={customer.id}
        year={yearValidFrom}
        month={currentMonth}
        isCreatingReportForParent={childrenForReport.length === 0}
        headAgreementNumber={headAgreement.headAgreementNumber}
        modeOfPayment={modeOfPaymemtOptions(customer?.modeOfPay ?? "")}
        invoiceCurrency={customer.invoiceCurrency.code}
        validFrom={headAgreement.valid.start?.isoString}
        validTo={headAgreement.valid.end?.isoString}
        includeCreditLimit={includeCreditLimit}
        setIncludeCreditLimit={setIncludeCreditLimit}
        includeThresholds={includeThresholds}
        setIncludeThresholds={setIncludeThresholds}
        specificationsText={specificationsText}
        setSpecificationsText={setSpecificationsState}
      />
      <ConditionsWithLastPage
        reportType={reportType}
        conditionsTexts={conditionsTexts}
        setConditionsTexts={setConditionsTexts}
        lastPageText={lastPageText}
        setLastPageText={setLastPageText}
      />
      <SurchargeStep
        headAgreementId={headAgreement.id}
        customerInvoiceCurrency={customer.invoiceCurrency.code}
        tableContext={additionalSurchargeDocumentsTableContext}
        setIsNextAvailable={setIsNextAvailable}
        yearValidFrom={yearValidFrom}
        yearValidTo={yearValidTo}
        loading={additionalSurchargeDocumentsLoading}
        additionalSurchargeDocuments={additionalSurchargeDocuments}
        isCompletingWizard={isCompletingWizard}
        selectedAgreements={selectedAgreements}
      />
    </WizardComponent>
  );
};
